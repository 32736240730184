import React, {
  useState, useCallback, useEffect, useMemo,
} from 'react';
import { useHistory } from 'react-router-dom';
import {
  Paper, Container, Box, Divider, Typography, Button,
} from '@material-ui/core';
import { isCancel } from 'axios';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';
import ValidationError from '../../assets/img/validation-error.webp';
import Header from '../../components/common/Header';
import SelectClient from '../../components/common/SelectClient';
import FileInput from '../../components/FileInput';
import MainButton from '../../components/MainButton';
import FormattedTypography from '../../components/common/FormattedTypography';
import {
  LMS_BULK_UPLOAD, modalIntitalSchema, snackBarInitialState,
  ROUTE_HOME, COMPLETED, FAILED, COMPLETED_WITH_ERRORS,
  AUTO_HIDE_DURATION,
} from '../../constants';
import { internalServerErrorModalLogic } from '../common/utils';
import {
  MCKINSEY_BLUE,
} from '../../stylesheets/colors';
import CustomUploadModal from '../../components/common/CustomUploadModal';
import { getErrorMessage } from '../../helpers/apiHelper';
import { parseValidationResult } from '../../helpers/xlsxValidation';
import AlertReleaseInfo from '../../components/ContentManagement/AlertReleaseInfo';
import { validateLMSUserApiCall, uploadXlsx } from './api';
import XlsScanError from '../../components/common/XlsScanError';
import { zeroAppender } from '../../helpers/formattingHelpers';
import ErrorModal from '../../components/ErrorModal/ErrorModal';
import CustomSnackbar from '../../components/common/CustomSnackbar';
import UploadStatusModal from './UploadStatusModal';

import {
  getProgress,
} from '../common/apis';

const useStyles = makeStyles(() => ({
  mainWrapper: {
    flexGrow: 1,
  },
  headerWrapper: {
    padding: '2rem',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    boxShadow: 'none',
    marginBottom: 0,
  },
  middleWrapper: {
    flex: 1,
    flexDirection: 'column',
    display: 'flex',
    padding: '1rem 2rem',
  },
  selectWrapper: {
    width: '25%',
  },
  border: {
    margin: '1.2rem 0',
    border: `0.5px dashed ${MCKINSEY_BLUE}`,
    width: '40%',
  },
  uploadPage: {
    marginTop: '2rem',
  },
  downloadWaveDataLbl: {
    marginTop: '1rem',
  },
}));

const LMSUserManagementPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const [selectedClient, setSelectedClient] = useState({});
  const [showAlertBar, setShowAlertBar] = useState(false);
  const [schema, setSchema] = useState(modalIntitalSchema);
  const [transactionId, setTransactionId] = useState(null);
  const [pollProgress, setPollProgress] = useState(false);
  const [snackbarObj, setSnackbarObj] = useState(snackBarInitialState);
  const [filename, setFilename] = useState('');
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [progress, setProgress] = useState({
    done: null, percentage: 0, status: '', queue_position: '',
  });
  const { done, status } = progress;
  useEffect(() => {
    let timer = null;
    const pollProgressApi = async () => {
      if (!transactionId || !pollProgress) {
        return;
      }
      try {
        const res = await getProgress(transactionId);
        const {
          done: progressDone, file_name: uploadFileName,
        } = res?.data;

        if (progressDone) {
          batchUpdates(() => {
            setProgress(res?.data);
            setPollProgress(false);
            setFilename(uploadFileName);
          });
        } else {
          batchUpdates(() => {
            setProgress(res?.data);
            setFilename(uploadFileName);
            setShowAlertBar(true);
          });
          timer = setTimeout(pollProgressApi, 1000);
        }
      } catch (err) {
        batchUpdates(() => {
          setSnackbarObj({ open: true, message: getErrorMessage(err), severity: 'error' });
          setShowAlertBar(false);
          setOpenStatusModal(false);
        });
        if (isCancel(err)) {
          return;
        }
        timer = internalServerErrorModalLogic(history, err, setIsErrorModalOpen, pollProgressApi);
      }
    };
    pollProgressApi();

    return () => {
      clearTimeout(timer);
    };
  }, [pollProgress, transactionId, history]);

  useEffect(() => {
    let timerObj;
    if (done
      && [COMPLETED, FAILED, COMPLETED_WITH_ERRORS].includes(status)) {
      timerObj = setTimeout(() => setShowAlertBar(false), AUTO_HIDE_DURATION);
    }
    return () => {
      clearTimeout(timerObj);
    };
  }, [done, status]);

  const isButtonDisabled = useMemo(
    () => Object.keys(selectedClient).length === 0, [selectedClient],
  );

  const resetFileUpload = useCallback(
    (e) => {
      e.target.value = '';
    },
    [],
  );

  const applyValidationModal = useCallback((errorMessages) => {
    const errorMessageCount = errorMessages.errors?.length || 0;
    setSchema({
      component: XlsScanError,
      wrapperProps: {
        logo: <img src={ValidationError} alt="validation error" width={120} height={120} />,
        heading: (
          <FormattedTypography
            prefix={`Scanning file ${filename}`}
            subHeading={`${zeroAppender(errorMessageCount)} errors found. Please make the required changes and re-upload your file.`}
          />
        ),
        primaryBtnText: 'Re-upload File',
        primaryBtnProps: { onClick: () => { setSchema(modalIntitalSchema); } },
      },
      props: errorMessages,
    });
  }, [filename]);

  const uploadFile = useCallback(
    async (formData) => {
      try {
        setShowAlertBar(true);
        setSchema(modalIntitalSchema);
        const uploadResult = await uploadXlsx('/lms/mck_id/upload', formData);
        const tid = uploadResult?.data?.transaction_id;
        if (tid) {
          batchUpdates(() => {
            setTransactionId(tid);
            setPollProgress(true);
          });
        } else {
          setShowAlertBar(false); // sometimes tId comes null as response
        }
      } catch (e) {
        batchUpdates(() => {
          setSnackbarObj({ open: true, message: getErrorMessage(e), severity: 'error' });
          setShowAlertBar(false);
        });
      }
    },
    [],
  );

  const onConfirmUpload = useCallback(async (formData) => {
    let result;
    try {
      result = await validateLMSUserApiCall('lms_mck_id_upload', formData);
    } catch (err) {
      batchUpdates(() => {
        setShowAlertBar(false);
        setSnackbarObj({ open: true, message: getErrorMessage(err), severity: 'error' });
      });
      return; // Early exit
    }
    const errorMessages = parseValidationResult(result);

    if (errorMessages.errors.length) {
      applyValidationModal(errorMessages);
      setShowAlertBar(false);
      return;
    }
    await uploadFile(formData);
  }, [applyValidationModal, uploadFile]);

  const onUpload = (e) => {
    const selectedFile = e.target.files[0];
    const formData = new FormData();
    resetFileUpload(e);
    formData.append('file', selectedFile, selectedFile.name);
    formData.append('client', selectedClient?.value);
    batchUpdates(() => {
      setFilename(selectedFile?.name);
      setShowAlertBar(true);
      setProgress({ done: null, percentage: 0 });
    });
    onConfirmUpload(formData);
  };

  const onDownloadSample = async () => {
    window.open(LMS_BULK_UPLOAD, '_blank');
  };

  const onViewStatus = () => {
    batchUpdates(() => {
      setOpenStatusModal(true);
    });
  };

  const onErrorModalClose = () => {
    setIsErrorModalOpen(false);
    history.replace(`/${ROUTE_HOME}`);
  };

  const ModalComponent = schema.component ? schema.component : null;

  return (
    <Paper elevation={0} className={classes.mainWrapper}>
      {schema.component
        && (
          <CustomUploadModal
            open={schema?.component}
            onClose={() => setSchema(modalIntitalSchema)}
            {...schema.wrapperProps}
          >
            <ModalComponent {...schema.props} />
          </CustomUploadModal>
        )}
      {showAlertBar ? (
        <AlertReleaseInfo
          programSubType={'lms_mck_id_upload'}
          progress={progress}
          showElement={showAlertBar}
          progressMessage={`Uploading LMS users file "${filename}"`}
          withErrorsMessage={`LMS users file "${filename}" upload was completed with errors.`}
          failedMessage={`Failed to upload LMS users file "${filename}". Please try again.`}
          successMessage={`LMS users file "${filename}" has been uploaded successfully`}
          cancellingMessage="Cancelling pending LMS user tasks..."
          cancelledMessage={`LMS users file "${filename}" upload was cancelled.`}
          onViewStatus={onViewStatus}
          setShowElement={setShowAlertBar}
        />
      ) : null}
      <Header
        heading="LMS Users"
        subHeading="Select client ID before uploading xls for user creation."
        className={classes.headerWrapper}
      />
      <Paper className={classes.wrapper} elevation={0}>
        <Container maxWidth={false} className={classes.middleWrapper}>
          <Box className={classes.selectWrapper}>
            <SelectClient selectedClient={selectedClient} setSelectedClient={setSelectedClient} />
          </Box>
          <Divider variant="fullWidth" className={classes.divider} light />
          <Box className={classes.uploadPage}>
            <Typography>Upload a list of users.</Typography>
            <Box className={classes.border}>
              <FileInput
                idx={0}
                onFileInput={onUpload}
                isDisabled={isButtonDisabled}
              >
                <MainButton
                  title="Upload XLS file"
                  subtitle={'Make sure to add correct email for the user(s)'}
                  isDisabled={isButtonDisabled}
                />
              </FileInput>
            </Box>
          </Box>
          <Divider variant="fullWidth" className={classes.divider} light />
          <Box className={classes.downloadTempBtn}>
            <Typography variant="body1" className={classes.downloadWaveDataLbl}>Need help configuring the file? Download the template below.</Typography>
            <Button
              variant="outlined"
              style={{ marginTop: '0.75rem', width: 'fit-content' }}
              color="primary"
              onClick={onDownloadSample}
            >
              Download Sample Template
            </Button>
          </Box>
        </Container>
      </Paper>
      {openStatusModal && done !== null ? (
        <UploadStatusModal
          open={openStatusModal}
          onClose={() => {
            setOpenStatusModal(false);
            setProgress({ done: null, percentage: 0 });
            setShowAlertBar(false);
          }}
          progress={progress}
          action="lms_mck_id_upload"
        />
      ) : null}
      <ErrorModal
        open={isErrorModalOpen}
        onClose={onErrorModalClose}
        message="Could not enroll users due to some technical error."
      />
      <CustomSnackbar snackbarObj={snackbarObj} setSnackbarObj={setSnackbarObj} />
    </Paper>
  );
};

export default LMSUserManagementPage;
