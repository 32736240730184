import React, {
  useState, useEffect, useCallback, useContext,
} from 'react';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';

import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TimelineActivity from '../common/TimelineActivity';
import EmptyTimeline from '../common/EmptyTimeline';
import UserEnrollment from './UserEnrollment';
import UserUnenrollment from './UserUnenrollment/UserUnenrollment';
import {
  AUTO_ENROLLMENT, BULK_UNENROLLMENT, BULK_UPDATE_ACCOUNTS, IN_PROGRESS, MODIFY_GRADE, UPLOAD_GRADE,
  SYNC_SESSION_ENROLLMENTS, SINGLE_SESSION_SYNC, CLONE_COURSE, WEBINAR_MANAGEMENT,
  BULK_EDIT_COMPONENT_METADATA, INDIVIDUAL_SCORM_REFRESH, MULTIPLE_SCORM_REFRESH,
  TRANSFER_COHORT_USERS,
  IMPORT_COURSE, COHORT_USER_METADATA, GROUP_ENROLLMENT, snackBarInitialState,
  BULK_UPDATE_COMPONENT_ACCESS, MARK_ALL_USERS_COMPLETE, REORDER_TRAINING_MATERIALS,
  REORDER_COMPONENTS, ADD_TRAINING_MATERIAL,
  EDIT_USER_DETAIL, USER_DELETION, USER_UNMARK,
  BULK_EDIT_USER_PASSWORD, BULK_USER_CREATION, LMS_MCK_ID_UPLOAD,
} from '../../constants';
import ActivateDeactivateUsers from './ActivateDeactivateUsers';
import AssignmentManagement from './AssignmentManagement';
import WorkshopManagement from './WorkshopManagement';
import WebinarManagement from './WebinarManagement';
import ContentManagement from './ContentManagement';
import BulkMetaData from './BulkMetaData';
import TransferCohortUsers from './TransferCohortUsers';
// eslint-disable-next-line import/no-cycle
import {
  downloadFile,
  getReport,
  cancelTransaction,
} from '../../containers/common/apis';
// // eslint-disable-next-line import/no-cycle
// import { Context } from '../../containers/ReportPage';
// // eslint-disable-next-line import/no-cycle
// import { MetaDataContext } from '../../containers/BasePage';
import MyContext from '../../context';
import GroupManagement from './GroupManagement';
import MarkAllUsersComplete from './MarkAllUsersComplete';
import CustomSnackbar from '../common/CustomSnackbar';
import UserProfileModal from './UserProfile';
import UserMarkDeletion from './UserMarkDeletion';
import LMSUserUpload from './LMSUserUpload';

const useStyles = makeStyles({
  logWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  emptyWrapper: {
    padding: '10rem',
  },
});

const ActivityLogFeatures = {
  [AUTO_ENROLLMENT]: {
    component: UserEnrollment,
  },
  [BULK_UNENROLLMENT]: {
    component: UserUnenrollment,
  },
  [BULK_UPDATE_ACCOUNTS]: {
    component: ActivateDeactivateUsers,
  },
  [MODIFY_GRADE]: {
    component: AssignmentManagement,
  },
  [UPLOAD_GRADE]: {
    component: AssignmentManagement,
  },
  [SYNC_SESSION_ENROLLMENTS]: {
    component: WorkshopManagement,
  },
  [SINGLE_SESSION_SYNC]: {
    component: WorkshopManagement,
  },
  [CLONE_COURSE]: {
    component: ContentManagement,
  },
  [WEBINAR_MANAGEMENT]: {
    component: WebinarManagement,
  },
  [MARK_ALL_USERS_COMPLETE]: {
    component: MarkAllUsersComplete,
  },
  [BULK_EDIT_COMPONENT_METADATA]: {
    component: BulkMetaData,
  },
  [INDIVIDUAL_SCORM_REFRESH]: {
    component: ContentManagement,
  },
  [MULTIPLE_SCORM_REFRESH]: {
    component: ContentManagement,
  },
  [MULTIPLE_SCORM_REFRESH]: {
    component: ContentManagement,
  },
  [ADD_TRAINING_MATERIAL]: {
    component: ContentManagement,
  },
  [TRANSFER_COHORT_USERS]: {
    component: TransferCohortUsers,
  },
  [IMPORT_COURSE]: {
    component: ContentManagement,
  },
  [COHORT_USER_METADATA]: {
    component: TransferCohortUsers,
  },
  [GROUP_ENROLLMENT]: {
    component: GroupManagement,
  },
  [BULK_UPDATE_COMPONENT_ACCESS]: {
    component: ContentManagement,
  },
  [REORDER_TRAINING_MATERIALS]: {
    component: ContentManagement,
  },
  [REORDER_COMPONENTS]: {
    component: ContentManagement,
  },
  [EDIT_USER_DETAIL]: {
    component: UserProfileModal,
  },
  [USER_DELETION]: {
    component: UserMarkDeletion,
  },
  [USER_UNMARK]: {
    component: UserMarkDeletion,
  },
  [BULK_USER_CREATION]: {
    component: UserMarkDeletion,
  },
  [BULK_EDIT_USER_PASSWORD]: {
    component: TransferCohortUsers,
  },
  [LMS_MCK_ID_UPLOAD]: {
    component: LMSUserUpload,
  },
};

const Timeline = ({
  timeline, configId, programId,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [transactionId, setTransactionId] = useState('');
  const [type, setType] = useState('');
  const [status, setStatus] = useState('');
  const [transactionData, setTransactionData] = useState({});
  const [pollProgressValue, setPollProgressValue] = useState(false);
  const [cancellingTransaction, setCancellingTransaction] = useState(false);
  // const { setIsActivityLogModalOpen } = useContext(
  //   (configId !== BULK_EDIT_METADATA && configId !== PLATFORM_USER) ? Context : MetaDataContext,
  // );
  const { setIsActivityLogModalOpen } = useContext(MyContext);
  const [snackbarObj, setSnackbarObj] = useState(snackBarInitialState);

  const classes = useStyles();

  const openViewLogs = useCallback((id, featureType, featureStatus, data) => {
    setIsActivityLogModalOpen(true);
    setTransactionId(id);
    setType(featureType);
    setStatus(featureStatus);
    setOpenModal(true);
    setTransactionData(data);
  }, [setIsActivityLogModalOpen]);

  const onCancelTransaction = useCallback(
    // eslint-disable-next-line react-hooks/exhaustive-deps
    async () => {
      setCancellingTransaction(true);
      try {
        const result = await cancelTransaction(transactionId);
        if (result?.data) {
          setCancellingTransaction(false);
        }
      } catch (e) {
        // TODO: Show error toast
        setCancellingTransaction(false);
        console.error(e);
      }
    }, [transactionId],
  );

  const onClose = () => {
    batchUpdates(() => {
      setIsActivityLogModalOpen(false);
      setPollProgressValue(false);
      setStatus('');
      setOpenModal(false);
      setCancellingTransaction(false);
    });
  };

  const updatePollProgress = (value) => {
    setPollProgressValue(value);
  };

  const updateLogStatus = (value) => {
    setStatus(value);
  };

  const onDownloadLog = async (transaction, q) => {
    try {
      const tid = transaction.transaction_id;
      const createdAt = transaction.created_at;
      await downloadFile(tid, configId, type, createdAt, q);
    } catch (e) {
      // TODO: Show error toast
      console.error(e);
    }
  };

  useEffect(() => {
    if (status.length > 0 && openModal) {
      if (IN_PROGRESS.includes(status)) {
        updatePollProgress(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal, status]);

  const Page = type.length > 0 ? ActivityLogFeatures[type]?.component : null;
  return (
    <Box className={classes.logWrapper}>
      {
        timeline.length > 0 ? timeline?.map((data) => (
          <TimelineActivity
            key={data.transaction_id}
            data={data}
            onViewDetails={
              (id, featureType, featureStatus) => openViewLogs(id, featureType, featureStatus, data)
            }
          />
        )) : (
          <Box className={classes.emptyWrapper}>
            <EmptyTimeline message="No data found..." />
          </Box>
        )
      }
      {type.length > 0 && (
        <>
          {
            openModal ? (
              <Page
                open={openModal}
                onClose={onClose}
                transactionId={transactionId}
                type={type}
                status={status}
                pollProgressValue={pollProgressValue}
                updatePollProgress={updatePollProgress}
                updateLogStatus={updateLogStatus}
                onDownloadLog={onDownloadLog}
                configId={configId}
                getReport={getReport}
                onCancelTransaction={onCancelTransaction}
                disableCancelOperation={cancellingTransaction}
                setCancellingTransaction={setCancellingTransaction}
                programId={programId}
                setSnackbarObj={setSnackbarObj}
                transactionData={transactionData}
              />
            )
              : null
          }
        </>
      )}
      <CustomSnackbar snackbarObj={snackbarObj} setSnackbarObj={setSnackbarObj} />
    </Box>
  );
};

Timeline.propTypes = {
  timeline: PropTypes.array.isRequired,
  configId: PropTypes.string.isRequired,
  programId: PropTypes.string.isRequired,
};

export default Timeline;
